<template>
	<div class="content">
		<div class="navBox flexBox alignCenter">
			<div class="logo imgBox" @click="onLink('/')">
				<img src="../assets/logo.png" alt="">
			</div>
			<div class="navList flexBox alignCenter">
				<div class="navItem" @click="onLink('/')">
					<div class="text flexBox alignCenter">首页</div>
				</div>

				<div class="navItem " @click="onLink('/standard')">
					<div class="text flexBox alignCenter">检测标准</div>
				</div>

				<div class="navItem activeItem">
					<div class="text flexBox alignCenter">品牌介绍</div>
				</div>

				<div class="navItem" @click="onLink('/about')">
					<div class="text flexBox alignCenter">联系我们</div>
				</div>
			</div>


		</div>

		<div class="container">
			<div class="imgList">
				<div class="itemItem imgBox">
					<img src="https://bhsd.oss-cn-hangzhou.aliyuncs.com/erp/chalin/brand1.png" alt="">
				</div>
				<div class="itemItem imgBox">
					<img src="https://bhsd.oss-cn-hangzhou.aliyuncs.com/erp/chalin/brand2.png" alt="">
				</div>
				<div class="itemItem imgBox">
					<img src="https://bhsd.oss-cn-hangzhou.aliyuncs.com/erp/chalin/brand3.png" alt="">
				</div>
				<div class="itemItem imgBox">
					<img src="https://bhsd.oss-cn-hangzhou.aliyuncs.com/erp/chalin/brand4.png" alt="">
				</div>
				<div class="itemItem imgBox">
					<img src="https://bhsd.oss-cn-hangzhou.aliyuncs.com/erp/chalin/brand5.png" alt="">
				</div>
				<div class="itemItem imgBox">
					<img src="https://bhsd.oss-cn-hangzhou.aliyuncs.com/erp/chalin/brand6.png" alt="">
				</div>
				<div class="itemItem imgBox">
					<img src="https://bhsd.oss-cn-hangzhou.aliyuncs.com/erp/chalin/brand7.png" alt="">
				</div>
				<div class="itemItem imgBox">
					<img src="https://bhsd.oss-cn-hangzhou.aliyuncs.com/erp/chalin/brand8.png" alt="">
				</div>

				<div class="itemItem imgBox">
					<img src="https://bhsd.oss-cn-hangzhou.aliyuncs.com/erp/chalin/brand9.png" alt="">
				</div>

				<div class="itemItem imgBox">
					<img src="https://bhsd.oss-cn-hangzhou.aliyuncs.com/erp/chalin/brand10.png" alt="">
				</div>

				<div class="itemItem imgBox">
					<img src="https://bhsd.oss-cn-hangzhou.aliyuncs.com/erp/chalin/brand11.png" alt="">
				</div>

				<div class="itemItem imgBox">
					<img src="https://bhsd.oss-cn-hangzhou.aliyuncs.com/erp/chalin/brand12.png" alt="">
				</div>

				<div class="itemItem imgBox">
					<img src="https://bhsd.oss-cn-hangzhou.aliyuncs.com/erp/chalin/brand13.png" alt="">
				</div>

				<div class="itemItem imgBox">
					<img src="https://bhsd.oss-cn-hangzhou.aliyuncs.com/erp/chalin/brand14.png" alt="">
				</div>

				<div class="itemItem imgBox">
					<img src="https://bhsd.oss-cn-hangzhou.aliyuncs.com/erp/chalin/brand15.png" alt="">
				</div>

				<div class="itemItem imgBox">
					<img src="https://bhsd.oss-cn-hangzhou.aliyuncs.com/erp/chalin/brand16.png" alt="">
				</div>


			</div>
		</div>

	</div>
</template>

<script>
	export default {
		name: 'HelloWorld',
		props: {
			msg: String
		},
		created() {

			document.body.scrollTop = document.documentElement.scrollTop = 0;
		},
		methods: {
			onLink(path) {
				this.$router.push({
					path: path
				})
			}
		}
	}
</script>

<style scoped>
</style>