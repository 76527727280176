<template>
	<div class="footBox">
		<div class="foot flexBox justifyCenter">
			<div class="list flexBox">
				<div class="item">
					<div class="title">首页展示</div>
					<div class="text">公司简介</div>
				</div>

				<div class="item">
					<div class="title">检测标准</div>
					<div class="text">层层筛选</div>
					<div class="text">权威检测</div>
				</div>

				<div class="item">
					<div class="title">品牌介绍</div>
					<div class="text">产品介绍</div>

				</div>

				<div class="item">
					<div class="title">联系我们</div>
					<div class="text">公司电话</div>
				</div>
			</div>
			<div class="codeBox">
				<div class="flexBox alignCenter" style="margin-bottom: 12px;">
					<div class="codeImg">
						<img src="../assets/code1.png" alt="">
					</div>
					<div class="text">扫一扫添加微信</div>
				</div>

				<div class="text textColor">电话：+86-18627570618</div>
				<!-- <div class="text textColor">地址：湖南省长沙市天心区终身教育大楼26</div> -->
			</div>
		</div>
		<div class="filingsBox flexCenter">
			<div class="text" style="margin-right: 24px;">Copyright © 2023-2028 湖南茶鳞科技有限公司 版权所有</div>
			<div class="text" style="margin-right: 54px; cursor: pointer;">
				<a href="https://beian.miit.gov.cn" target="_blank">湘ICP备2023022006号</a>
			</div>
			<div class="text textColor" style="margin-right: 24px;">免责声明：该网站部分素材来自互联网，如有侵权请相告，我们立即删除，谢谢</div>
			<div class="text textColor">技术支持：湖南茶鳞科技</div>

		</div>
	</div>
</template>

<script>
	export default {
		name: 'footBox',
		props: {
			msg: String
		},
		methods: {
			goBeian() {
				window.location.href = 'https://beian.miit.gov.cn'
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
	.foot {
		width: 100%;
		height: 320px;
		background: #333333;
		padding-top: 40px;

		.list {
			.item {
				margin-right: 154px;

				.title {
					font-size: 16px;
					font-weight: 500;
					color: #FFFFFF;
					line-height: 22px;
					margin-bottom: 16px;
					border-bottom: 1px solid #979797;
					padding-bottom: 18px;
				}

				.text {
					font-size: 14px;
					font-weight: 500;
					color: #999999;
					line-height: 20px;
					margin-bottom: 10px;
				}
			}
		}

		.codeBox {
			width: 320px;

			.codeImg {
				width: 130px;
				height: 130px;
				margin-right: 16px;
				padding: 10px;
				background: #fff;
			}

			.text {
				font-size: 14px;
				font-weight: 500;
				color: #FFFFFF;
				line-height: 20px;
			}

			.textColor {

				color: #999;
			}
		}
	}

	.filingsBox {
		width: 100%;
		height: 66px;
		background: #1A1A1D;

		.text {
			font-size: 13px;
			font-weight: 500;
			color: #999999;
			line-height: 18px;

			a {
				text-decoration: none;
				/* 去掉下划线 */
				color: inherit;
				/* 继承父元素颜色 */
			}

			a:hover {
				// text-decoration: underline;
				/* 在鼠标移上去时添加下划线 */
				color: #666;

			}

		}

		.textColor {

			color: #565656;



		}

	}
</style>