<template>
	<div class="content">
		<div class="navBox flexBox alignCenter">
			<div class="logo imgBox" @click="onLink('/')">
				<img src="../assets/logo.png" alt="">
			</div>
			<div class="navList flexBox alignCenter">
				<div class="navItem" @click="onLink('/')">
					<div class="text flexBox alignCenter">首页</div>
				</div>

				<div class="navItem " @click="onLink('/standard')">
					<div class="text flexBox alignCenter">检测标准</div>
				</div>

				<div class="navItem " @click="onLink('/brand')">
					<div class="text flexBox alignCenter">品牌介绍</div>
				</div>

				<div class="navItem activeItem">
					<div class="text flexBox alignCenter">联系我们</div>
				</div>
			</div>


		</div>

		<div class="container flexColumn alignCenter ">
			<div class="aboutBox">
				<div class="titleBox flexBox alignCenter">联系我们</div>
				<div class="list">
					<div class="item">
						<div class="title">全国地区</div>
						<div class="infoBox flexBox alignEnd">
							<div class="flexFull">
								<div class="name">伍先生</div>
								<div class="flexBox alignCenter">
									<div class="icon flexBox">
										<img src="../assets/about/phone.png" alt="">
									</div>
									<div class="text">+86-18529506225</div>
								</div>

								<!-- <div class="flexBox alignCenter">
									<div class="icon flexBox">
										<img src="../assets/about/wx.png" alt="">
									</div>
									<div class="text">-whdd-r967v</div>
								</div> -->
							</div>

							<div>
								<div class="codeImg flexBox">
									<img src="../assets/code2.png" alt="">
								</div>
								<div class="codeText">扫一扫添加微信</div>
							</div>

						</div>
					</div>

					<div class="item">
						<div class="title">湖南地区</div>
						<div class="infoBox flexBox alignEnd">
							<div class="flexFull">
								<div class="name">李女士</div>
								<div class="flexBox alignCenter">
									<div class="icon flexBox">
										<img src="../assets/about/phone.png" alt="">
									</div>
									<div class="text">+86-18627570618</div>
								</div>

								<!-- <div class="flexBox alignCenter">
									<div class="icon flexBox">
										<img src="../assets/code1.png" alt="">
									</div>
									<div class="text">-whdd-r967v</div>
								</div> -->
							</div>

							<div>
								<div class="codeImg flexBox">
									<img src="../assets/code1.png" alt="">
								</div>
								<div class="codeText">扫一扫添加微信</div>
							</div>

						</div>
					</div>

					<div class="item">
						<div class="title">四川地区</div>
						<div class="infoBox flexBox alignEnd">
							<div class="flexFull">
								<div class="name">伍先生</div>
								<div class="flexBox alignCenter">
									<div class="icon flexBox">
										<img src="../assets/about/phone.png" alt="">
									</div>
									<div class="text">+86-18529506225</div>
								</div>

								<!-- <div class="flexBox alignCenter">
									<div class="icon flexBox">
										<img src="../assets/about/wx.png" alt="">
									</div>
									<div class="text">-whdd-r967v</div>
								</div> -->
							</div>

							<div>
								<div class="codeImg flexBox">
									<img src="../assets/code2.png" alt="">
								</div>
								<div class="codeText">扫一扫添加微信</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'HelloWorld',
		props: {
			msg: String
		},
		created() {

			document.body.scrollTop = document.documentElement.scrollTop = 0;
		},
		methods: {
			onLink(path) {
				this.$router.push({
					path: path
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	.content {
		min-height: 100vh;

		background: #FAFAFA;

		.container {
			padding-top: 24px;
		}

		.aboutBox {
			width: 1200px;
			margin-bottom: 48px;
			padding-top: 48px;
			background: #FFFFFF;
			border-radius: 12px;

			.titleBox {
				width: 1100px;
				height: 77px;
				background: #EACC84;
				padding-left: 72px;
				font-size: 32px;
				font-weight: 500;
				color: #FFFFFF;
				line-height: 45px;

			}

			.list {


				.item {
					border-bottom: 1px solid #E5E5E5;
					padding: 64px 72px;

					&:last-child {
						border-bottom: none;

					}

					.title {
						font-size: 38px;
						font-weight: 600;
						color: rgba(0, 0, 0, 0.8);
						line-height: 53px;
						margin-bottom: 36px;
					}

					.name {
						font-size: 32px;
						font-weight: 500;
						color: #333333;
						line-height: 45px;
						margin-bottom: 24px;
					}

					.icon {
						width: 36px;
						height: 36px;
						margin-right: 10px;
					}

					.text {
						font-size: 26px;
						font-weight: 500;
						color: #949494;
						line-height: 36px;
					}

					.codeImg {
						width: 96px;
						height: 96px;
						margin-right: 270px;
					}

					.codeText {
						font-size: 14px;
						margin-top: 4px;
						font-weight: 500;
						color: #999999;
						line-height: 20px;
					}
				}
			}

			.phoneBox {
				margin-bottom: 70px;

				.text {
					font-size: 20px;
					font-weight: 500;
					color: rgba(0, 0, 0, 0.8);
					line-height: 28px;
				}

				.num {
					font-size: 28px;
					font-weight: 500;
					color: #DFC37D;
					line-height: 40px;
				}

			}

			.locationBox {
				.text {
					font-size: 28px;
					font-weight: 500;
					color: rgba(0, 0, 0, 0.8);
					line-height: 40px;
					margin-bottom: 24px;
				}
			}
		}
	}
</style>