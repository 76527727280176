import VueRouter from "vue-router";


import index from "../view/index"
import standard from "../view/standard"
import brand from "../view/brand"
import about from "../view/about"
export default new VueRouter({
	routes: [{
			path: '/',
			component: index
		},
		{
			path: '/standard',
			component: standard
		},
		{
			path: '/brand',
			component: brand
		},
		{
			path: '/about',
			component: about
		}

	]
})