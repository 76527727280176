<template>
	<div class="content">
		<div class="navBox flexBox alignCenter" :class="{navBoxBg:isNavBox}">
			<div class="logo imgBox">
				<img src="../assets/logo.png" alt="">
			</div>
			<div class="navList flexBox alignCenter">
				<div class="navItem  activeItem">
					<div class="text flexBox alignCenter">首页</div>
				</div>

				<div class="navItem" @click="onLink('/standard')">
					<div class="text flexBox alignCenter">检测标准</div>
				</div>

				<div class="navItem" @click="onLink('/brand')">
					<div class="text flexBox alignCenter">品牌介绍</div>
				</div>

				<div class="navItem" @click="onLink('/about')">
					<div class="text flexBox alignCenter">联系我们</div>
				</div>
			</div>

		</div>

		<div class="titleBox">

			<!-- <img src="../assets/indexTitle.png" alt=""> -->

			<mySwiper :swiperList="swiperList"></mySwiper>
		</div>
		<div class="container flexColumn alignCenter ">
			<div class="">
				<div class="fishList flexBox justifyCenter">
					<div class="fishItem " :class="{fish1:isBounceInRight}">
						<img src="../assets/fish1.png" alt="">
					</div>

					<div class="fishItem " :class="{fish2:isBounceInRight}">
						<img src="../assets/fish2.png" alt="">
					</div>

					<div class="fishItem " :class="{fish3:isBounceInRight}">
						<img src="../assets/fish3.png" alt="">
					</div>

					<div class="fishItem " :class="{fish4:isBounceInRight}">
						<img src="../assets/fish4.png" alt="">
					</div>
				</div>

				<div class="introduceBox">
					<div class="title">独特的原始鱼种</div>
					<div class="text">茶鳞水产包含：茶鳞花鲢、茶鳞黄金鲫、茶鳞鳊鱼、茶鳞翘嘴、茶鳞黄辣丁、茶鳞大河蚌、茶鳞野生甲鱼；更多水产，敬请期待...</div>
				</div>

				<div class="list flexBox">
					<div class="item item1">
						<div class="img1 imgBox">
							<img src="../assets/display1.png" alt="">
						</div>
					</div>
					<div class="item item2">
						<div class="img2 imgBox">
							<img src="../assets/display2.png" alt="">
						</div>
						<div class="img3 imgBox">
							<img src="../assets/display3.png" alt="">
						</div>
					</div>
					<div class="item item3">
						<div class="img4 imgBox">
							<img src="../assets/display4.png" alt="">
						</div>
						<div class="img5 imgBox">
							<img src="../assets/display5.png" alt="">
						</div>
					</div>
				</div>

			</div>

		</div>
	</div>
</template>



<script>
	import mySwiper from '../components/mySwiper.vue'

	export default {
		name: 'HelloWorld',
		props: {
			msg: String
		},
		components: {
			mySwiper
		},
		data() {
			return {
				isBounceInRight: false,
				isNavBox: false,
				swiperList: [{
					imgUrl: 'https://bhsd.oss-cn-hangzhou.aliyuncs.com/erp/chalin/indexTitle.a45d7dc8.png'
				}, {
					imgUrl: 'https://bhsd.oss-cn-hangzhou.aliyuncs.com/erp/chalin/indexTitle1.0b83743a.png'
				}, {
					imgUrl: 'https://bhsd.oss-cn-hangzhou.aliyuncs.com/erp/chalin/indexTitle2.744818ef.png'
				}, {
					imgUrl: 'https://bhsd.oss-cn-hangzhou.aliyuncs.com/erp/chalin/indexTitle3.ddd16e41.png'
					// imgUrl: require('@/assets/indexTitle3.png')
				}]
			};
		},
		created() {
			document.body.scrollTop = document.documentElement.scrollTop = 0;
		},
		mounted() {
			window.addEventListener('scroll', this.scrollToTop)
		},
		methods: {
			onLink(path) {
				this.$router.push({
					path: path
				})
			},
			scrollToTop() {
				var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				if (scrollTop > 300) {
					this.isBounceInRight = true
				}
				if (scrollTop > 70) {
					this.isNavBox = true
				} else {
					this.isNavBox = false
				}


			},

		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.content {
		overflow-x: hidden;



		.titleBox {
			width: 100%;
			// display: flex;
			// margin-top: 30px;

			img {
				width: 100%;
			}
		}

		.container {
			padding: 90px 0 120px;

			.fishList {
				margin-bottom: 60px;
				height: 336px;
				width: 100%;

				.fishItem {
					width: 218px;
					height: 336px;
					margin-right: 20px;
					float: left;
					opacity: 0;

					&:last-child {
						margin-right: 0;
					}

					img {
						width: 100%;
					}
				}

				.fish1 {
					width: 486px;
					opacity: 1;
					animation: bounceInRight 0.6s;
				}

				.fish2 {
					opacity: 1;
					animation: bounceInRight 1.2s;
				}

				.fish3 {
					opacity: 1;
					animation: bounceInRight 1.8s;
				}

				.fish4 {
					opacity: 1;
					animation: bounceInRight 2.4s;
				}
			}

			.introduceBox {
				margin-bottom: 60px;

				.title {
					font-size: 24px;
					font-weight: 600;
					color: #333333;
					line-height: 33px;
					margin-bottom: 18px;
				}

				.text {
					font-size: 18px;
					font-family: PingFangSC-Semibold, PingFang SC;
					font-weight: 600;
					color: #666666;
					line-height: 25px;
				}
			}

			.list {
				.item {
					margin-right: 20px;
					// transform-style: preserve-3d;
					// perspective: 1000;

					&:last-child {
						margin-right: 0;
					}

					.img2,
					.img4 {
						margin-bottom: 20px;
					}

					.imgBox {
						// transition: 0.8s;

						&:hover {
							// transform: rotateY(360deg);
						}
					}

				}

				.item1 {
					width: 500px;
				}

				.item2 {
					width: 330px;
				}

				.item3 {
					width: 330px;
				}
			}
		}
	}


	@keyframes bounceInRight {

		0%,
		60%,
		75%,
		90%,
		100% {
			// animation-timing-function: cubic-bezier(.215, .61, .355, 1);
		}

		0% {
			opacity: 0;
			transform: translate3d(3000px, 0, 0);

		}

		60% {
			opacity: 1;
			transform: translate3d(-16px, 0, 0);
		}


		// 90% {
		// 	opacity: 1;
		// 	transform: translate3d(-5px, 0, 0);
		// }

		100% {
			opacity: 1;
			transform: translateZ(0);
		}
	}
</style>