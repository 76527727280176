<template>
	<div>
		<router-view></router-view>
		<footBox></footBox>

	</div>

</template>

<script>
	import footBox from './components/footBox.vue' // 新建
	export default {
		name: 'App',
		components: {
			footBox
		}
	}
</script>

<style lang="scss">
	body {
		margin: 0;
		scrollbar-width: none;
		-ms-overflow-style: none;
		/* IE 10+ */


		img {
			width: 100%;
		}

		.imgBox {
			display: flex;
		}
	}

	div {
		box-sizing: border-box;

	}

	::-webkit-scrollbar {

		display: none;
		/* Chrome Safari */

	}

	.flexBox {
		display: flex;
	}

	.flexColumn {
		display: flex;
		flex-direction: column;
	}

	.justifyCenter {
		justify-content: center;
	}

	.justifyBetween {
		justify-content: space-between;
	}

	.justifyEnd {
		justify-content: flex-end;
	}

	.alignCenter {
		align-items: center;
	}

	.alignEnd {
		align-items: flex-end;
	}

	.alignStart {
		align-items: flex-start;
	}

	.flexCenter {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.flexFull {
		flex: 1;
	}

	.flexShrink {
		flex-shrink: 0;
	}

	.flexCenter {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.flexWrap {
		flex-wrap: wrap;
	}

	.relative {
		position: relative;
	}

	.ellipsis {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
	}

	.ellipsis2 {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		/* autoprefixer: off */
		-webkit-box-orient: vertical;
		/* autoprefixer: on */
		-webkit-line-clamp: 2;
		line-clamp: 2;
	}

	.content {
		overflow-x: hidden;
		padding-top: 70px
	}

	.navBox {
		height: 70px;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		padding: 0 96px;
		z-index: 9999;
		background-color: #fff;

		.logo {
			width: 161px;
			height: 58px;
			margin-right: 56px;
		}

		.navList {
			height: 100%;
			transform: all 1s;

			.navItem {
				height: 100%;
				padding: 0 34px;
				font-size: 18px;
				color: #333333;
				line-height: 26px;
				cursor: pointer;

				.text {
					position: relative;
					height: 100%;
				}
			}

			.activeItem {

				font-weight: 500;
				color: #ECCF87;

				.text {
					&::after {
						content: '';
						height: 4px;
						background-color: #ECCF87;
						position: absolute;
						bottom: 0;
						left: 0;
						width: 100%;
					}
				}

			}
		}
	}

	// .navBoxBg {
	// 	background-color: #333333;

	// 	.navList {
	// 		.navItem {
	// 			color: #fff;
	// 		}
	// 	}


	// }
</style>