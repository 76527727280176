<template>
	<div class="swiper-main">
		<swiper :options="swiperOption" ref="mySwiper">
			<swiper-slide v-for="(item, index) in swiperList" :key="index">
				<img :src="item.imgUrl" alt="" />
			</swiper-slide>
			<div class="swiper-pagination" slot="pagination"></div>
			<!-- 箭头 -->
			<!-- <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div> -->
		</swiper>
	</div>
</template>

<script>
	// 引入插件
	import {
		swiper,
		swiperSlide
	} from "vue-awesome-swiper";
	import 'swiper/css'


	export default {
		name: "Swiper",
		props: {
			swiperList: Array, // 注意此处我的数据是由接口返回的数据
		},
		components: {
			swiper,
			swiperSlide,
		},
		data() {
			return {
				swiperOption: {
					speed: 2000,
					loop: true,
					autoplay: true,
					spaceBetween: 30,
					effect: 'fade',
					disableOnInteraction: false,
					// pagination: {
					// 	el: '.swiper-pagination',
					// 	clickable: true
					// },
					// navigation: {
					// 	nextEl: '.swiper-button-next',
					// 	prevEl: '.swiper-button-prev'
					// }
				}
			};
		},
		computed: {
			swiper() {
				return this.$refs.mySwiper.swiper;
			},
		},
		mounted() {
			// current swiper instance
			// 然后你就可以使用当前上下文内的swiper对象去做你想做的事了
			console.log("this is current swiper instance object", this.swiper);
			// this.swiper.slideTo(3, 1000, false);
		},
	};
</script>
<style scoped>
	.swiper-main {
		width: 100%;

	}

	.swiper-container {
		width: 100%;

	}

	.swiper-container img {
		width: 100%;

	}

	::v-deep .swiper-slide {
		transition: 2s;
	}
</style>